/* eslint-disable camelcase */
import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import { Link } from 'gatsby';

const mapDrinksToItems = (drinks) =>
  drinks.map(
    ({
      node: {
        name,
        slug,
        category,
        image,
        alcoholic,
        ingredients,
        glass,
        skill,
        id,
      },
    }) => {
      return {
        as: Link,
        key: id,
        to: `/drink/${slug}/`,
        childKey: slug,
        image: (
          <img
            wrapped="false"
            ui="true"
            alt={name + ' cocktail'}
            src={
              image
                ? `/images/drinks-images/${image}`
                : '/images/drink-image.svg'
            }
          />
        ),
        header: {
          as: 'h3',
          content: name,
        },
        meta: (
          <Card.Meta>
            <h4>{category}</h4>
            <p>
              <Icon name="percent" />{' '}
              {alcoholic ? <> Alcoholic</> : 'Non-alcoholic'}
            </p>
            <p>
              {ingredients.map((ingredient, i) => (
                <span key={i.toString()}>{ingredient._0}, </span>
              ))}
            </p>
            <p>
              <Icon name="glass martini" /> Served in a {glass}
            </p>

            {skill && (
              <p>
                <Icon name="configure" />
                <span> Skill: {skill}</span>
              </p>
            )}
          </Card.Meta>
        ),
      };
    }
  );

export default ({ drinks }) => (
  <Card.Group
    className="drink-list"
    items={mapDrinksToItems(drinks)}
    centered
  />
);
